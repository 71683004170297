/* eslint-disable import/no-unused-modules */
import React from 'react';
import { graphql } from 'gatsby';
import Helmet from '../../components/Helmet';
import Features from '../../modules/features';
import withI18n from '../../hocs/withI18n';

export const Head = withI18n(() => {
  return <Helmet pageName="featuresCustomers" />;
});

const customerFeature = () => {
  return (
    <Features featureName="customer-management-crm-software-for-businesses" />
  );
};
export default customerFeature;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
